@import "../../includes/settings.scss";

.range-container {
    flex: 1 1 100%;
    margin: 2rem;
    
    .rc-slider {
        height: 10px;
        width: 100%;
        border-radius: 5px;
        -webkit-appearance: none;
        height: 10px;
        padding: 0.25rem;
        border: 1px solid gray;
        background: whitesmoke;
        transition: opacity 0.2s;
        box-sizing: border-box;
    }
    .rc-slider * {
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    }
    .rc-slider-track {
        position: absolute;
        left: 0;
        height: 10px;
        border-radius: 5px;
        background-color: $custom-purple;
        z-index: 1;
    }
    .rc-slider-handle {
        outline: none;
        position: absolute;
        margin-left: -7px;
        margin-top: -5px;
        width: 24px;
        height: 24px;
        cursor: pointer;
        border-radius: 50%;
        border: solid 2px $custom-purple;
        background-color: $custom-gray;
        z-index: 2;
    }
    .rc-slider-handle:hover {
        background-color: whitesmoke;
    }
    .rc-slider-handle-active:active {
        box-shadow: 0 0 5px #57c5f7;
    }
    .rc-slider-mark {
        position: absolute;
        top: 10px;
        left: 0;
        width: 100%;
        font-size: 12px;
        z-index: 3;
    }
    .rc-slider-mark-text {
        position: absolute;
        display: inline-block;
        vertical-align: middle;
        text-align: center;
        cursor: pointer;
        color: #999;
    }
    .rc-slider-mark-text-active {
        color: #666;
    }
    .rc-slider-step {
        position: absolute;
        width: 100%;
        height: 4px;
        background: transparent;
        z-index: 1;
    }
    .rc-slider-tooltip-zoom-down-enter,
    .rc-slider-tooltip-zoom-down-appear {
        -webkit-animation-duration: 0.3s;
        animation-duration: 0.3s;
        -webkit-animation-fill-mode: both;
        animation-fill-mode: both;
        display: block !important;
        -webkit-animation-play-state: paused;
        animation-play-state: paused;
    }
    .rc-slider-tooltip-zoom-down-leave {
        -webkit-animation-duration: 0.3s;
        animation-duration: 0.3s;
        -webkit-animation-fill-mode: both;
        animation-fill-mode: both;
        display: block !important;
        -webkit-animation-play-state: paused;
        animation-play-state: paused;
    }
    .rc-slider-tooltip-zoom-down-enter.rc-slider-tooltip-zoom-down-enter-active,
    .rc-slider-tooltip-zoom-down-appear.rc-slider-tooltip-zoom-down-appear-active {
        -webkit-animation-name: rcSliderTooltipZoomDownIn;
        animation-name: rcSliderTooltipZoomDownIn;
        -webkit-animation-play-state: running;
        animation-play-state: running;
    }
    .rc-slider-tooltip-zoom-down-leave.rc-slider-tooltip-zoom-down-leave-active {
        -webkit-animation-name: rcSliderTooltipZoomDownOut;
        animation-name: rcSliderTooltipZoomDownOut;
        -webkit-animation-play-state: running;
        animation-play-state: running;
    }
    .rc-slider-tooltip-zoom-down-enter,
    .rc-slider-tooltip-zoom-down-appear {
        -webkit-transform: scale(0, 0);
        transform: scale(0, 0);
        -webkit-animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
        animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
    }
    .rc-slider-tooltip-zoom-down-leave {
        -webkit-animation-timing-function: cubic-bezier(
            0.755,
            0.05,
            0.855,
            0.06
        );
        animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    }
    @-webkit-keyframes rcSliderTooltipZoomDownIn {
        0% {
            opacity: 0;
            -webkit-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
            -webkit-transform: scale(0, 0);
            transform: scale(0, 0);
        }
        100% {
            -webkit-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
            -webkit-transform: scale(1, 1);
            transform: scale(1, 1);
        }
    }
    @keyframes rcSliderTooltipZoomDownIn {
        0% {
            opacity: 0;
            -webkit-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
            -webkit-transform: scale(0, 0);
            transform: scale(0, 0);
        }
        100% {
            -webkit-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
            -webkit-transform: scale(1, 1);
            transform: scale(1, 1);
        }
    }
    @-webkit-keyframes rcSliderTooltipZoomDownOut {
        0% {
            -webkit-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
            -webkit-transform: scale(1, 1);
            transform: scale(1, 1);
        }
        100% {
            opacity: 0;
            -webkit-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
            -webkit-transform: scale(0, 0);
            transform: scale(0, 0);
        }
    }
    @keyframes rcSliderTooltipZoomDownOut {
        0% {
            -webkit-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
            -webkit-transform: scale(1, 1);
            transform: scale(1, 1);
        }
        100% {
            opacity: 0;
            -webkit-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
            -webkit-transform: scale(0, 0);
            transform: scale(0, 0);
        }
    }
    .rc-tooltip {
        position: absolute;
        left: -9999px;
        top: -9999px;
        z-index: 4;
        visibility: visible;
        box-sizing: border-box;
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    }
    .rc-tooltip * {
        box-sizing: border-box;
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    }
    .rc-tooltip-hidden {
        display: none;
    }
    .rc-tooltip-placement-top {
        padding: 4px 0 8px 0;
    }
    .rc-tooltip-inner {
        padding: 6px 2px;
        min-width: 24px;
        height: 24px;
        font-size: 12px;
        line-height: 1;
        text-align: center;
        text-decoration: none;
        border-radius: 6px;
        box-shadow: 0 0 4px #d9d9d9;
    }
    .rc-tooltip-arrow {
        position: absolute;
        width: 0;
        height: 0;
        border-color: transparent;
        border-style: solid;
    }
    .rc-tooltip-placement-top .rc-tooltip-arrow {
        bottom: 4px;
        left: 50%;
        margin-left: -4px;
        border-width: 4px 4px 0;
        border-top-color: #6c6c6c;
    }
}