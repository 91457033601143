@import "../../includes/settings.scss";

.filter-container {
    padding: 2rem;
    justify-content: flex-start;

    .filters-header {
        border-bottom: 1px solid $text-dark;
        width: 100%;
        justify-content: space-between;

        h3 {
            text-align: center;
            margin: 1rem 0;
            font-size: 28px;
            font-weight: 400;
        }

        .clear-filters {
            filter: grayscale(100%) opacity(0.7);
            transition: $transition-speed;

            svg {
                width: 2rem;
                height: 2rem;

                .primary-color {
                    color: $custom-purple;
                }
            }
        }
        :hover {
            filter: grayscale(0) opacity(1);
        }
    }

    .filterItem-container {
        padding: 1rem 0;
        padding-left: 2rem;
        border-bottom: 1px solid $text-dark;
        cursor: pointer;
        width: 100%;

        .header {
            flex: 1 1 100%;
            justify-content: flex-start;
            h3 {
                color: $card-text;
                margin: 1rem 0;
                font-size: 20px;
            }
        }
        .header:hover {
            h3 {
                color: $custom-purple;
            }
        }
        .body {
            flex: 1 1 100%;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            transition: 0.4s;

            .content {
                flex: 1 1 100%;

                .item {
                    justify-content: flex-start;
                    flex: 1 1 80%;
                    cursor: pointer;
                    h4 {
                        margin: 0.5rem 0;
                        font-size: 20px;
                        font-weight: 500;
                        color: $card-text;
                    }
                    :hover {
                        color: $custom-purple;
                    }

                    //slider
                    .slider-container {
                        flex: 1 1 100%;
                        padding-right: 2rem;

                        .label-container {
                            flex: 1 1 100%;
                            label {
                                font-size: 16px;
                                font-weight: 500;
                                flex: 1 1 45%;
                            }
                            .min {
                                text-align: left;
                            }
                            .max {
                                text-align: right;
                            }
                        }
                        .slider {
                            width: 80%;
                            -webkit-appearance: none;
                            height: 10px;
                            padding: 0.25rem;
                            border: 1px solid gray;
                            background: whitesmoke;
                            -webkit-transition: 0.2s;
                            transition: opacity 0.2s;
                        }

                        .slider:hover {
                            opacity: 0.8;
                        }

                        .slider::-webkit-slider-thumb {
                            -webkit-appearance: none;
                            appearance: none;
                            border-radius: 30px;
                            width: 30px;
                            height: 30px;
                            background: $custom-purple;
                            cursor: pointer;
                        }

                        .slider::-moz-range-thumb {
                            width: 30px;
                            height: 30px;
                            background: $custom-purple;
                            cursor: pointer;
                        }
                    }
                }
            }
        }

        .body.show {
            overflow: unset;
            max-height: auto;
            margin-top: 1rem;
        }

        .body.hidden {
            overflow: hidden;
            max-height: 0px;
            margin-top: unset;
            border-top: unset;
        }
    }
    .filterItem-container:hover {
        border-color: $custom-purple;
    }
}

// Phone
@media only screen and (min-device-width: $phone) and (max-device-width: $tablet) {
    .filter-container {
        padding: 1rem;
        justify-content: center;

        .filters-header {
            justify-content: center;
            margin-top: 0.75rem;

            .clear-filters {
                margin: 0.25rem 0;
            }
        }

        .filterItem-container {
            padding: 0.75rem 0;

            .header {
                justify-content: center;

                h3 {
                    font-size: 22px;
                }
            }
            .body {
                .content {
                    .item {
                        justify-content: center;

                        .slider-container {
                            padding: 0 0.5rem;
                        }
                    }
                }
            }
        }
    }
}

//Tablet
@media only screen and (min-device-width: $tablet) and (max-device-width: $desktop) {
    .filter-container {
        padding: 2rem !important;
        justify-content: center;

        .filters-header {
            margin: 0.5rem 0 0 0 !important;
            justify-content: space-between;
        }
        .filterItem-container {
            padding: 0.75rem 0;

            .header {
                justify-content: center;

                h3 {
                    font-size: 22px;
                }
            }
            .body {
                .content {
                    .item {
                        justify-content: center;

                        .slider-container {
                            padding: 0 0.5rem;
                        }
                    }
                }
            }
        }
    }
}
