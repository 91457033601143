@import "../../App.scss";

.navbar-container {
    flex: 1 1 100%;
    width: 100%;

    .navbar-mobile {
        display: none;
    }
    .navbar {
        padding: 0;
        .navbar-desktop {
            padding: 1rem 0;
            flex: 1;
            justify-content: space-between;
            margin: 0 2rem 0 4rem;
            align-items: flex-start;

            .navbar-left {
                flex: 1 1 5%;
            }
            .navbar-main {
                flex: 1 1 60%;
                margin-top: 1.5rem;

                ul {
                    display: flex;
                    flex: 1 1 100%;
                    list-style: none;

                    .menu-item {
                        margin: 0;
                    }
                    a {
                        font-size: 16px;
                        margin: 0 1rem;
                        color: $text-dark;
                        letter-spacing: 0.25px;
                        font-weight: 500;
                    }
                    a:hover {
                        border-bottom: 3px solid $custom-purple;
                    }
                    a.active {
                        padding-bottom: 5px;
                        color: $custom-purple;
                        border-bottom: 3px solid $custom-purple;
                    }
                }
            }
            .navbar-right {
                flex: 1 1 10%;
                margin-top: 1.5rem;
                justify-content: flex-end;

                .login-container {
                    justify-content: flex-end;
                }
                .dropdown {
                    flex-direction: column;
                    cursor: pointer;
                    flex: 1 1 100%;
                    align-content: flex-end;

                    .dropbtn {
                        h3 {
                            font-size: 20px;
                            color: white;
                            font-style: italic;
                            font-weight: 500;
                        }
                        img {
                            transition: 0.6s;
                            margin-left: 1rem;
                        }
                        .upArrow {
                            transform: rotateX(180deg);
                        }
                    }
                    .dropdown-menu {
                        z-index: 100;
                        width: 100%;
                        align-items: flex-start;
                        display: none;
                        border-radius: 5px;
                        border: 1px solid $custom-gray;
                        box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
                        position: absolute;
                        top: 40px;
                        flex-direction: column;
                        padding: 1rem 0;
                        background-color: $custom-gray;
                        overflow: hidden;

                        .list-item {
                            width: 100%;
                            text-align: left;
                            justify-content: flex-start;
                            padding-left: 2rem;
                            cursor: pointer;
                            margin: 1rem 0;
                            font-size: 18px;
                            font-weight: 500;
                        }
                        .list-item:hover {
                            color: $custom-purple;
                            text-decoration: underline;
                        }
                    }
                    .show {
                        display: flex;
                    }
                }
            }
        }
    }
}

// Phone
@media only screen and (max-width: $tablet) {
    .navbar-container {
        .navbar-desktop {
            display: none;
        }
        .navbar-mobile {
            display: flex;
            flex: 1;
            padding: 2rem 1rem;
            list-style: none;

            .menu-item {
                .login-container {
                    a {
                        font-size: 16px;
                        font-weight: 600;
                        color: white;
                    }
                    .dropdown {
                        flex-direction: column;
                        cursor: pointer;
                        margin: 0 2rem;

                        .dropbtn {
                            h3 {
                                font-size: 20px;
                                color: $text-dark;
                                font-style: italic;
                                font-weight: 500;
                            }
                            img {
                                transition: 0.6s;
                                margin-left: 1rem;
                            }
                            .upArrow {
                                transform: rotateX(180deg);
                            }
                        }
                        .dropdown-menu {
                            z-index: 100;
                            width: 150%;
                            align-items: flex-start;
                            display: none;
                            border-radius: 5px;
                            border: 1px solid $custom-gray;
                            box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
                            position: absolute;
                            top: 40px;
                            flex-direction: column;
                            padding: 1rem;
                            background-color: $custom-gray;

                            .list-item {
                                cursor: pointer;
                                margin: 1rem 0;
                                font-weight: 600;
                                a {
                                    color: $text-dark;
                                }
                            }
                        }
                        .show {
                            display: flex;
                        }
                    }
                }
            }
        }
    }
}
