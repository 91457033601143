@import "../App.scss";

.class-container {
    flex: 1 1 100%;

    .twoCol-container {
        flex: 1 1 100%;
        padding: 4rem 6rem 0 15%;
        background-color: $custom-gray;
        align-items: flex-start;

        .container-column {
            justify-content: flex-end;
        }
        .right {
            flex-basis: 35%;
            margin-right: 6rem;
            padding: 0 1rem;
            flex-direction: column;
            align-items: flex-start;
            align-content: flex-start;

            .top-container {
                flex-direction: column;
                align-items: flex-start;
                width: 90%;
                align-content: flex-start;
                h3 {
                    margin-bottom: 0.5rem;
                    font-size: 14px;
                    font-weight: 600;
                    color: $card-text;
                }
                h2 {
                    margin-bottom: 0.5rem;
                    font-size: 32px;
                    font-weight: 500;
                }
                p {
                    width: 90%;
                    margin: 0.5rem 0;
                }
            }
            .info-container {
                flex-direction: column;
                width: 90%;

                hr {
                    width: 100%;
                }
                .row {
                    justify-content: space-between;
                    width: 100%;
                    margin: 0.5rem 0;

                    img {
                        margin-right: 1rem;
                    }
                    p {
                        font-weight: 500;
                        font-size: 1em;
                    }
                    strong {
                        color: $custom-purple;
                    }
                }
            }
            .buttons-container {
                width: 90%;
                justify-content: space-between;
                .btn-twitter {
                    width: unset;
                    padding: 0.7rem 0.6rem;
                    margin: 1rem 0;
                }
                .btn-watch {
                    width: unset;
                    padding: 0.7rem 1rem;
                    margin: 1rem 0;
                }
            }
        }
        .left {
            flex-basis: 50%;

            .image-container {
                flex: 1 1 100%;

                img {
                    min-width: 100%;
                }
            }
        }
    }
    .multiCol-container {
        background-color: $custom-gray;
        flex: 1 1 100%;

        .multiCol-header {
            align-self: end;
            margin-left: 15%;
            padding: 0 1rem;

            h1 {
                margin: 1rem 0;
                font-size: 36px;
                font-weight: 500;
            }
        }

        .container-row {
            align-content: flex-start;
            justify-content: flex-start;

            .container-column {
                margin-left: 15%;
                flex-basis: 50%;
                justify-content: flex-start;

                h2 {
                    margin: 1rem 0;
                    font-size: 32px;
                    font-weight: 500;
                }
                ul {
                    list-style: none;

                    li {
                        counter-increment: list;
                    }
                }
                ul li::before {
                    content: counter(list);
                    color: white;
                    text-align: center;
                    background-color: $custom-purple;
                    border-radius: 100px;
                    padding: 1rem;
                    font-weight: 800;
                    display: inline-block;
                    width: 1em;
                    height: 1em;
                    transform: translate(-150%, 120%);
                }
                h3 {
                    margin: 1rem 0 0.5rem 0;
                    font-size: 14px;
                    font-weight: 600;
                    color: $custom-purple;
                }

                p {
                    margin: 1rem 0;
                }
            }
        }
    }
    .gallery-container {
        padding: 5% 10%;

        h1 {
            font-size: 36px;
            font-weight: 500;
        }
        .featured-image {
        img {
            
            max-width: 100%;
        }
        }
        .images-container {
            .img {
                flex: 1 1 10%;
            }
            img {
                max-width: 100%;
            }
        }

    }
}

// Phone
@media only screen and (min-device-width: $phone) and (max-device-width: $tablet) {
    .class-container {
        .twoCol-container {
            padding: 4rem 0 2rem 0;

            .right {
                flex-basis: 100%;
                margin-right: unset;

                .top-container {
                    width: 100%;
                    padding-top: 1rem;
                }
                .info-container {
                    width: 100%;
                }
                .buttons-container {
                    width: 100%;
                }
            }
            .left {
                flex-basis: 100%;
            }
        }
        .multiCol-container {
            padding: 0 1rem;

            .multiCol-header {
                padding: unset;
                margin-left: unset;
            }

            .container-row {
                padding: unset;

                .container-column {
                    margin-left: unset;
                    flex-basis: 100%;

                    ul li::before {
                        transform: translate(0);
                    }
                }
            }
        }
    }
}

//Tablet
@media only screen and (min-device-width: $tablet) and (max-device-width: $desktop) {
    .class-container {
        .twoCol-container {
            padding: 2rem 2rem 0 2rem;

            .right {
                flex-basis: 100%;
                margin-right: 0;
                padding-top: 1rem;
                .top-container,
                .info-container,
                .buttons-container {
                    width: 100%;
                }
                .buttons-container {
                    justify-content: flex-start;

                    .btn-twitter,
                    .btn-watch {
                        margin: 1rem 0.5rem 1rem 0;
                    }
                }
            }
            .left {
                flex-basis: 50%;
            }
        }
        .multiCol-container {
            padding: 0 2rem 2rem 2rem;

            .multiCol-header {
                padding: 0 1rem;
                margin-left: 0;
            }

            .container-row {
                padding: 0 1rem;

                .container-column {
                    margin-left: 0;
                    flex-basis: 100%;

                    ul {
                        margin-left: 2rem;
                    }
                    ul li::before {
                        transform: translate(-150%, 120%);
                    }
                }
            }
        }
        .gallery-container {
            .featured-image {
                img {
                    width: 85%;
                }
            }
        }
    }
}
