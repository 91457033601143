@import "../App.scss";

.contact-container {
    flex: 1 1 100%;

    .form-container {
        margin: unset;
    }
    .contact-header {
        flex-direction: column;
        margin: 2rem 0;

        h1 {
            margin: 2rem 0;
            font-size: 3em;
            font-weight: 500;
        }
        p {
            margin: 1rem 0;
            width: 60%;
            text-align: center;
        }
    }
}

// Phone
@media only screen and (min-device-width: $phone) and (max-device-width: $tablet) {
    .contact-container {
        .contact-header {
            h1 {
                font-size: 40px;
            }
            p {
                width: 80%;
            }
        }
    }
}

//Tablet
@media only screen and (min-device-width: $tablet) and (max-device-width: $desktop) {
    .contact-container {
    }
}
