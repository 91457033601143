// Colors
$primary: #50BC9B;
$secondary: #F58123;
$text-light: white;
$text-dark: #454545;

$custom-purple: #4b57b4;
$header-dark-text: #1a1a1a;
$login-custom-purple: #494085;
$custom-twitter: #666666;
$form-purple: #b4abd6;
$custom-gray: #f2f2f2;
$card-text: #868686;
$update-btn: #87cefa;
$delete-btn: #da2727;

// transition
$transition-speed: 600ms;

// Breakpoints
$phone: 320px;
$tablet: 768px;
$desktop: 1024px;
