@import "../../App.scss";
.form-container {
    .image-container {
        padding: 7% 20%;
        background-image: url("../../../assets/webAssets/bg-form.jpg");
        background-repeat: no-repeat;
        background-size: cover;
    }

    .contact-form {
        padding: 4rem 4rem 6rem 4rem;
        background: rgba(90, 76, 153, 0.9);

        .header-container {
            margin: 2rem 0;
            flex-direction: column;

            h1 {
                color: white;
                font-size: 2.5em;
                font-weight: 500;
            }
            p {
                margin: 2rem 0;
                color: white;
                font-size: 20px;
                font-weight: 500;
            }
        }
        .fields-container {
            .form-row {
                .field-group {
                    ::after {
                        display: none;
                    }
                    .form-input-container {
                        textArea,
                        input {
                            border: none;
                            background-color: $form-purple;
                        }
                        ::placeholder {
                            /* Chrome, Firefox, Opera, Safari 10.1+ */
                            color: white;
                            font-size: 1em;
                            font-weight: 600;
                        }
                        textArea {
                            padding-bottom: 4rem;
                        }
                    }
                }
            }
        }
        .button-container {
            justify-content: center;
            flex: 1 1 100%;

            button {
                flex: 0 0 auto;
                padding: 0.7rem 1rem;
                width: unset;
            }
        }
    }
}
// Phone
@media only screen and (min-device-width: $phone) and (max-device-width: $tablet) {
    .form-container {
        .image-container {
            padding: unset;
        }
        .contact-form {
            padding: 2rem 1rem;

            .header-container {
                text-align: center;

                h1 {
                    font-size: 30px;
                }
            }
            .fields-container {
                .form-row {
                    .field-group {
                        .form-input-container {
                            .form-error {
                                text-align: center;
                            }
                        }
                    }
                }
            }
        }
    }
}
//Tablet
@media only screen and (min-device-width: $tablet) and (max-device-width: $desktop) {
    .form-container {
        .image-container {
            padding: 10%;
        }
        .contact-form {
            padding: 2rem;

            .fields-container {
                .form-row {
                    .field-group {
                        .form-input-container {
                            .form-error {
                                text-align: left;
                            }
                        }
                    }
                }
            }
        }
    }
}

//Large Screen
@media only screen and (min-device-width: 2560px) {
    .form-container {
        .image-container {
            padding: 15% 20%;
        }
    }
}
