@import "../../includes/settings.scss";
.admin-container {
    flex: 1 1 100%;

    main {
        .btn-watch {
            width: unset;
        }
    }
}
// Phone
@media only screen and (min-device-width: $phone) and (max-device-width: $tablet) {
    .admin-container {
        main {
            margin-left: 0;
            padding: 0;
        }
    }
}
