@import "../App.scss";

.about-container {
    flex: 1 1 100%;

    .twoCol-container {
        margin-top: 4rem;
        align-items: stretch;

        .container-column {
            justify-content: flex-start;
            h1 {
                margin: 1rem 0;
                font-size: 3em;
                font-weight: 500;
            }
            p {
                margin: 1rem 0;
            }
        }
        .right {
            flex-basis: 30%;
            padding: 1rem 8rem;
        }
    }
    .multiCol-container {
        justify-content: flex-start;
        height: 55vh;

        background-image: url("../../assets/webAssets/bg-story.jpg");
        background-size: cover;
        background-repeat: no-repeat;

        .container-row {
            align-content: flex-start;
            padding-top: 6rem;
            justify-content: flex-start;

            .container-column {
                margin-left: 15%;
                flex-basis: 50%;
                justify-content: flex-start;

                h1 {
                    margin: 1rem 0;
                    font-size: 3em;
                    font-weight: 500;
                }

                p {
                    width: 80%;
                    margin: 0.5rem 0;
                    line-height: 125%;
                }
            }
        }
    }
}

// Phone
@media only screen and (min-device-width: $phone) and (max-device-width: $tablet) {
    .about-container {
        .twoCol-container {
            padding: unset;
            text-align: center;

            .right {
                flex-basis: 100%;
                padding: 1rem 2rem;
            }
        }
        .multiCol-container {
            text-align: center;
            height: unset;

            .container-row {
                padding-top: 4rem;

                .container-column {
                    flex-basis: 100%;
                    margin-left: unset;

                    p {
                        width: 100%;
                    }
                }
            }
        }
    }
}

//Tablet
@media only screen and (min-device-width: $tablet) and (max-device-width: $desktop) {
    .about-container {
        .twoCol-container {
            align-items: stretch;
            text-align: left;
        
            .container-column {
                justify-content: center;
            
            }
        }
        .multiCol-container {
            justify-content: flex-start;
            height: unset;
            text-align: left;
            background-position-x: 60%;

            .container-row {
                padding-top: 4rem;

                .container-column {
                    margin-left: 5%;
                    flex-basis: 70%;

                    p {
                        width: 80%;
                        margin: 0.5rem 0;
                        line-height: 125%;
                    }
                }
            }
        }
    }
}
