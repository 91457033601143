@import "../../includes/settings.scss";

.List-Container {
    flex: 1 1 100%;

    .list-navbar {
        display: flex;
        flex: 1 1 100%;
        justify-content: flex-end;

        ul {
            list-style: none;
            margin: 1rem 0;

            li {
                margin: 0 0.5rem;
            }
        }
    }

    .head {
        flex: 1 1 100%;
        padding: 2rem 0;
        background-image: url("../../../assets/webAssets/bg-footer.jpg");
        background-size: cover;
        background-repeat: no-repeat;
        background-position-y: 18%;
    }
    .create-container {
        margin: 1rem 0;
        flex: 1 1 100%;
        margin: 0 3rem;
        justify-content: flex-end;
    }
    .list {
        display: block;
        width: 100%;
        // overflow-x: auto;
        -webkit-overflow-scrolling: touch;

        .mobile-hide {
            // Phone
            @media only screen and (min-device-width: $phone) and (max-device-width: $tablet) {
                display: none;
            }
        }
        table {
            width: 100%;
            margin-bottom: 1rem;
            color: #212529;
            border-collapse: collapse;

            th,
            td {
                padding: 0.75rem;
                vertical-align: middle;
                border-top: 1px solid #dee2e6;
                text-align: center;
            }

            thead {
                th {
                    vertical-align: bottom;
                    border-bottom: 2px solid #dee2e6;
                }
            }

            tbody {
                tr:nth-of-type(odd) {
                    background-color: rgba(0, 0, 0, 0.05);
                    &:hover {
                        background-color: rgba(0, 0, 0, 0.1);
                        cursor: pointer;
                    }
                }
            }

            tr {
                &:hover {
                    background-color: rgba(0, 0, 0, 0.1);
                    cursor: pointer;
                }
            }
        }
    }
}

// Phone
@media only screen and (min-device-width: $phone) and (max-device-width: $tablet) {
    main {
        margin-left: 0;
    }
    .List-Container {
        padding: 6rem 0 0 0;
        justify-content: center;

    .head {
        background-image: none;
        background-color: $text-dark;
    
        h1 {
            color: whitesmoke;
        }
    }
        .list {
            padding: 0 1rem;
        }

        .create-container {
            margin: 0;
        }
    }
}

//Tablet
@media only screen and (min-device-width: $tablet) and (max-device-width: $desktop) {
    .filter-container {
        padding: 4rem;
        justify-content: center;

        .filters-header {
            margin: 1rem 0;
            width: 70%;
        }
        .filterItem-container {
            width: 70%;
        }
    }
}
