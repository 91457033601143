@import "../../App.scss";

// Phone
@media only screen and (min-device-width: $phone) and (max-device-width: $desktop) {
    .sideDrawer {
        background-image: url("../../../assets/webAssets/bg-footer.jpg");
        background-size: cover;
        background-repeat: no-repeat;
        .top-container {
            display: flex;
            flex: 1 1 auto;
            width: 100%;
            margin: 1rem 0 0 0;

            .logo-container {
                margin-left: 10%;
            }
            .close-container {
                button {
                    flex: 1 1 auto;
                }
            }
        }
        .middle-container {
            margin: 2rem 0;
            width: unset;
            align-self: flex-start;
            padding-left: 10%;

            .menu-container {
                list-style: none;

                .menu-item {
                    margin-bottom: 1rem;
                    a {
                        color: $text-light;
                        font-size: 20px;
                        line-height: 30px;
                        letter-spacing: 0.25px;
                        font-weight: 500;
                    }
                    a.active {
                        padding-bottom: 5px;
                        font-weight: 600;
                        color: $custom-gray;
                        border-bottom: 3px solid $custom-purple;
                    }
                }
            }
        }
        .bottom-container {
            .socials-container {
                flex: 1 1 100%;

                img {
                    width: 40px;
                    height: 40px;
                    margin: 0 1rem;
                }
            }
        }
    }
}
