@import "../App.scss";

.home-container {
    flex: 1;

    .header-container {
        flex: 1;
        align-content: flex-start;

        background-image: url("../../assets/webAssets/bg-main-banner.jpg");
        background-size: cover;
        background-repeat: no-repeat;

        height: 90vh;

        .Jumbotron-container {
            margin-left: 10%;

            .jumbotron {
                background-color: transparent;
                padding: 2rem;

                .body {
                    justify-content: flex-start;

                    .content {
                        padding-left: 2rem;
                        flex-direction: column;
                        align-items: center;

                        h2 {
                            font-size: 75px;
                            color: $header-dark-text;
                            text-align: center;
                            font-weight: 400;

                            strong {
                                color: $custom-purple;
                                font-weight: 400;
                                font-style: italic;
                            }
                        }

                        p {
                            margin: 1rem 0;
                            font-weight: 500;
                            font-size: 16px;
                            letter-spacing: 0.5px;
                            margin-bottom: 1.5rem;
                        }
                    }
                }
            }
        }
    }
    .twoCol-container {
        margin: 4rem 0;

        .container-column {
            h1 {
                margin: 1rem 0;
                font-size: 38px;
                font-weight: 500;
            }

            p {
                margin: 0.5rem 0;
            }

            button {
                margin: 1rem 0;
                width: unset;
                padding: 0.75rem 1.25rem;
            }

            .woman-image {
                transform: translate(20%, 20%);
            }
            .background-image {
                position: absolute;
                z-index: -5;
                margin-top: 5%;
                max-width: 75%;
            }
        }
        .right {
            flex-basis: 20%;
            justify-content: flex-start;
            margin: 0 4rem;
        }
    }
    .multiCol-container {
        margin-top: 4rem;
        background-color: $custom-gray;
        flex: 1 1 100%;

        .multiCol-header {
            flex-direction: column;
            padding: 2rem;

            h1 {
                margin: 1rem;
                font-size: 38px;
                font-weight: 500;
            }
            h4 {
                color: $custom-purple;
            }
        }
        .error-container {
            flex-direction: column;
            padding: 2rem;
            text-align: center;

            h3 {
                margin-bottom: 1rem;
            }
        }

        .container-row {
            justify-content: space-evenly;
            align-items: stretch;

            .column {
                flex-basis: 30%;

                .card {
                    height: 100%;
                    margin: 0 1rem;
                    box-shadow: unset;
                    border-radius: 0;
                    align-content: space-between;

                    .image-container {
                        flex: 1 1 100%;

                        .thumbnail {
                            max-height: 310px;
                            width: 100%;
                        }
                        button {
                            opacity: 0.7;
                            top: 100px;
                            position: absolute;
                        }
                    }
                    .body-container {
                        align-items: center;
                        text-align: center;
                        h2 {
                            margin: 1.5rem 1rem 0.25rem 1rem;
                            font-size: 32px;
                            font-weight: 500;
                        }
                        p {
                            margin: 1rem;
                            font-size: 14px;
                            font-weight: 600;
                            color: $card-text;

                            overflow: hidden;
                            text-overflow: ellipsis;
                            display: -webkit-box;
                            -webkit-line-clamp: 3;
                            -webkit-box-orient: vertical;
                        }
                    }
                }
            }
        }
        .container-bottom {
            margin: 2rem 0;
            button {
                width: 200px;
                padding: 0.75rem 1rem;
            }
        }
    }
    .mobile-testimonial {
        display: none;
    }
    .desktop-testimonial {
        flex: 1 1 100%;
        display: flex;
    }
    .testimonials-container {
        background-image: url("../../assets/webAssets/bg-testimonials.jpg");
        background-size: cover;
        background-repeat: no-repeat;
        background-position-y: 80%;

        .testimonials {
            .testimonial-body {
                height: 400px;
                justify-content: flex-start;

                .description {
                    margin-bottom: 1rem;
                    color: $text-dark;
                    font-size: 18px;
                    font-weight: 500;
                    text-align: center;
                    max-width: 50%;
                    font-style: italic;
                }
                p {
                    color: $custom-purple;
                    font-size: 16px;
                    font-weight: 500;
                    margin: 1rem 0;
                }

                .buttons-container {
                    position: absolute;
                    bottom: 0;
                    flex: 1;
                    margin: 2rem 0;
                    width: 100%;

                    input[type="radio"] {
                        margin: 0 1rem;
                        flex-basis: 30px;
                        -webkit-appearance: none;
                        border-radius: 30px;
                        border: 1px solid transparent;
                        outline-offset: 0;
                        background-color: whitesmoke;
                        height: 1.15rem;
                        border-radius: 100%;
                        outline: none;
                    }
                    input[type="radio"]:checked {
                        background-color: $custom-purple;
                    }
                    input[type="radio"]:hover {
                        cursor: pointer;
                    }
                }
            }
        }
    }
    .twoCol-container-alt {
        padding: 8rem 0;
        background-color: $custom-gray;
        z-index: 0;
        flex: 1;

        .container-column {
            display: flex;
            flex: 1 1 40%;
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;
            padding: 0 1rem;

            .woman-image {
                transform: translateX(-20%);
            }
            .background-image {
                position: absolute;
                z-index: -5;
                max-width: 50%;
                left: 0;
            }

            img {
                max-width: 100%;
            }
        }
        .left {
            align-content: flex-end;
            flex-basis: 20%;
            justify-content: flex-start;
            margin: 0 4rem;
            flex-direction: column;
            h1 {
                margin: 1rem 0;
                font-size: 38px;
                font-weight: 500;
            }

            h4 {
                margin: 0.5rem 0;
                color: $custom-purple;
            }

            button {
                margin: 1.25rem 0;
                width: unset;
                padding: 0.75rem 1.25rem;
            }
        }
    }
}

// Phone
@media only screen and (min-device-width: $phone) and (max-device-width: $tablet) {
    .home-container {
        flex: 1;
        .header-container {
            background-position-x: 65%;

            .Jumbotron-container {
                margin-left: unset;
                padding: unset;

                .jumbotron {
                    padding: unset;

                    .body {
                        margin-top: 5rem;
                        justify-content: center;

                        .content {
                            flex: 0 1 80%;
                            padding: 1rem;
                            text-align: center;
                            background-color: #ffffff;
                            border-radius: 30px;
                            opacity: 0.9;
                            margin-top: 1rem;

                            h2 {
                                font-size: 35px;
                            }
                        }
                    }
                }
            }
        }
        .twoCol-container {
            .container-column .woman-image {
                transform: translate(0);
            }

            .right {
                justify-content: center;
                text-align: center;
                flex-basis: unset;
                margin: 4rem 0 1rem 0;
            }
        }
        .multiCol-container {
            .multiCol-header {
                text-align: center;
            }
            .container-row {
                .column {
                    flex-basis: 100%;

                    .card {
                        margin: 1rem 0;
                        height: unset;

                        .image-container {
                            button {
                                width: unset;
                            }
                        }

                        .body-container {
                            button {
                                top: -30%;
                            }
                        }
                    }
                }
            }
        }
        .mobile-testimonial {
            flex: 1 1 100%;
            display: flex;
        }
        .desktop-testimonial {
            display: none;
        }
        .testimonials-container {
            background-image: url("../../assets/webAssets/bg-footer.jpg");
            background-size: cover;
            background-repeat: no-repeat;

            .testimonials {
                padding: 1rem;

                .testimonial-body {
                    height: unset;
                    min-height: 70vh;
                    margin-top: 2rem;

                    .description {
                        color: $text-light;
                        max-width: unset;
                        width: 100%;
                    }
                    strong {
                        color: $text-light;
                    }

                    .buttons-container {
                        bottom: -15%;
                        width: 80%;

                        input[type="radio"] {
                            margin: 0;
                        }
                    }
                }
            }
        }
        .twoCol-container-alt {
            padding-top: 2rem;
            padding-bottom: 6rem;
            .container-column {
                flex: 1 1 100%;
                padding: 0 1rem;

                .background-image {
                    left: unset;
                    max-width: 80%;
                }
                .woman-image {
                    transform: translateX(0);
                }
            }
            .left {
                flex-basis: 100%;
                margin: 0 0 4rem 0;
                text-align: center;

                h1 {
                    width: 100%;
                }
                button {
                    width: 300px;
                    padding: 0.5rem 1rem;
                }
            }
        }
    }
}

//Tablet
@media only screen and (min-device-width: $tablet) and (max-device-width: $desktop) {
    .home-container {
        flex: 1;
        .header-container {
            background-position-x: 60%;

            .Jumbotron-container {
                padding: 1.5rem;
                margin-left: unset;
                .jumbotron {
                    padding: 2rem;

                    .body {
                        justify-content: center;
                        margin-top: 4rem;
                        .content {
                            padding-left: unset;
                            flex: 1;

                            h2 {
                                font-size: 60px;
                            }

                            strong {
                                font-weight: 400;
                            }

                            p {
                                margin-top: 2rem;
                                font-size: 24px;
                            }
                        }
                    }
                }
            }
        }
        .twoCol-container {
            margin-top: 4rem;

            .container-column .woman-image {
                transform: translate(0);
            }

            .right {
                margin: 10rem 0 1rem 0;
            }
        }
        .multiCol-container {
            .container-row {
                .column {
                    flex-basis: 32%;

                    .card {
                        margin: 1rem 0;
                        height: 100%;
                        align-content: baseline;

                        .body-container {
                            button {
                                width: 200px;
                                padding: 0.1rem;
                                top: -30%;
                                font-size: 14px;
                            }
                        }
                    }
                }
            }
        }
        .mobile-testimonial {
            display: flex;
        }
        .desktop-testimonial {
            display: none;
        }
        .testimonials-container {
            background-image: url("../../assets/webAssets/bg-footer.jpg");
            background-size: cover;
            background-repeat: no-repeat;
            .testimonials {
                padding: 1rem 2rem;

                .testimonial-body {
                    margin-top: 8rem;
                    margin-bottom: 2rem;
                    min-height: unset;

                    .buttons-container {
                        bottom: -55%;
                    }
                    .description {
                        color: white;
                    }
                }
            }
        }
        .twoCol-container-alt {
            padding-bottom: 8rem;
            .left {
                align-content: center;
                margin: 0 0 8rem 0;
            }
        }
    }
}

//Tablet
@media only screen and (max-device-width: $desktop) and (min-device-width: $tablet) {
    .home-container {
        .header-container {
            .Jumbotron-container {
                .jumbotron {
                    .body {
                        justify-content: center;
                        margin-top: 4rem;
                        .content {
                            flex: 1;
                            padding: 1rem;
                            text-align: center;
                            background-color: #ffffff;
                            border-radius: 30px;
                            opacity: 0.9;
                            margin-top: 1rem;

                            h2 {
                                font-size: 60px;
                            }

                            strong {
                                font-weight: 400;
                            }

                            p {
                                margin-top: 2rem;
                                font-size: 24px;
                            }
                        }
                    }
                }
            }
        }
    }
}

//1024
@media only screen and (width: $desktop) {
    .home-container {
        .twoCol-container {
            .right {
                margin: 0;
            }
        }
        .twoCol-container-alt {
            .left {
                margin: 0 4rem;
                text-align: center;
            }
        }
    }
}

// small screen laptops
@media only screen and (min-device-width: 1025px) {
    .home-container {
        .header-container {
            background-position-x: 60%;
            .Jumbotron-container {
                margin-left: 0;
                .jumbotron {
                    .body {
                        justify-content: center;

                        .content {
                            flex: 0 1 80%;
                            padding: 1rem;
                            text-align: center;
                            background-color: #ffffff;
                            border-radius: 30px;
                            opacity: 0.9;
                            margin-top: 1rem;
                        }
                    }
                }
            }
        }
    }
}

//xs phone
@media only screen and (min-device-width: $phone) and (max-device-width: 374px) {
    .home-container {
        .header-container {
            height: 100vh;
            .Jumbotron-container {
                .jumbotron {
                    .body {
                        margin-top: 0;

                        .content {
                            flex: 0 1 80%;
                            padding: 1rem;
                            text-align: center;
                            background-color: #ffffff;
                            border-radius: 30px;
                            opacity: 0.9;
                            margin-top: 1rem;

                            h2 {
                                font-size: 35px;
                            }
                        }
                    }
                }
            }
        }
    }
}
