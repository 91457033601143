@import "../App.scss";

.members-container {
    flex: 1 1 100%;

    .container-row {
        background-color: $custom-gray;
        flex: 1 1 100%;
        padding: 0 1rem;
        align-items: flex-start;

        .container-col {
            flex-direction: column;
            flex: 1 1 20%;
        }

        .right {
            flex: 1 1 65%;

            .multiCol-container {
                padding: 2rem 0;
                flex: 1 1 100%;

                .error-container {
                    width: 100%;
                    flex: 1 1 100%;
                    flex-direction: column;
                    padding: 4rem 0;
                    text-align: center;

                    h3 {
                        margin-bottom: 1rem;
                    }
                }
                .container-row {
                    justify-content: space-evenly;
                    align-items: flex-start;

                    .column {
                        flex-basis: 30%;
                        min-width: 325px;
                        align-content: flex-start;

                        .card {
                            box-shadow: unset;
                            border-radius: 0;
                            padding-bottom: 2rem;
                            min-height: 470px;
                            align-content: space-between;
                            overflow: hidden;

                            .image-container {
                                flex: 1 1 100%;

                                .upper-container {
                                    flex: 1 1 100%;
                                }
                                .thumbnail {
                                    width: 100%;
                                    max-height: 310px;
                                }
                                .youtube {
                                    cursor: pointer;
                                    position: absolute;
                                    top: 50%;
                                    left: 50%;
                                    margin-left: -25px;
                                    margin-top: -20px;
                                }
                            }
                            .body-container {
                                align-items: center;
                                text-align: center;
                                h2 {
                                    margin: 0.5rem 0;
                                    font-size: 32px;
                                    font-weight: 500;

                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                    display: -webkit-box;
                                    -webkit-line-clamp: 2;
                                    -webkit-box-orient: vertical;
                                }
                                h3 {
                                    margin: 0.5rem 0;
                                    font-size: 14px;
                                    font-weight: 600;
                                    color: $card-text;
                                }
                                p {
                                    margin: 0 1rem;
                                    font-size: 14px;

                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                    display: -webkit-box;
                                    -webkit-line-clamp: 8;
                                    -webkit-box-orient: vertical;
                                }
                                .info-container {
                                    width: 100%;
                                    justify-content: space-evenly;

                                    img {
                                        margin-right: 0.5rem;
                                    }
                                    p {
                                        font-weight: 500;
                                        margin: 1rem 0;
                                        font-size: 1em;
                                    }
                                    strong {
                                        color: $custom-purple;
                                    }
                                }
                            }
                        }
                    }
                }
                .container-bottom {
                    button {
                        width: 200px;
                        padding: 1rem;
                    }
                }
            }

            .pagination {
                padding: 0 0 2rem 0;
            }
        }
    }
    .twoCol-container {
        flex: 1 1 100%;
        padding: 4rem 6rem;
        background-image: linear-gradient(white, whitesmoke, $custom-gray);
        align-items: stretch;

        .container-column {
            justify-content: flex-start;
            h1 {
                z-index: 5;
                margin: 1rem 0;
                font-size: 46px;
                font-weight: 500;
            }
            p {
                z-index: 5;
                margin: 1rem 0;
            }
            ul {
                z-index: 5;
                list-style: none;
                margin-left: 10px;

                li {
                    margin: 0.5rem 0;
                }
            }
            ul li::before {
                content: "\2022";
                color: $custom-purple;
                font-weight: 900;
                display: inline-block;
                width: 1.5em;
                margin-left: -1em;
            }
        }
        .left {
            flex-basis: 40%;
            padding: 1rem 2rem;
            flex-direction: column;
            align-items: flex-start;

            .legit-image {
                max-width: 70%;
                position: absolute;
                top: -5%;
            }
            button {
                margin: 1rem 0;
                width: unset;
                padding: 0.7rem 1rem;
            }
        }
        .right {
            flex-basis: 50%;
        }
    }
}

// Phone
@media only screen and (min-device-width: $phone) and (max-device-width: $tablet) {
    .members-container {
        .text-container {
            h1 {
                font-size: 40px;
            }
        }
        .container-row {
            .container-col {
                flex: 1 1 100%;

                .multiCol-container {
                    .container-row {
                        padding: unset;

                        .column {
                            flex: 1 1 100%;
                            min-width: unset;

                            .card {
                                margin: 1rem 0;
                            }
                        }
                    }
                }
            }
        }

        .twoCol-container {
            padding: unset;
            text-align: center;

            .left {
                flex-basis: 100%;
                padding: 1rem 2rem;
                align-items: center;

                .legit-image {
                    top: 5%;
                }
            }
            .right {
                margin: 1rem 0;
                flex-basis: 100%;
                img {
                    max-width: 100%;
                    transform: translate(0);
                }
            }
        }
    }
}

//Tablet
@media only screen and (min-device-width: $tablet) and (max-device-width: $desktop) {
    .members-container {
        .container-row {
            .container-col {
                flex: 1 1 100%;

                .multiCol-container {
                    .container-row {
                        padding: unset;

                        .column {
                            max-width: 325px;
                            flex: 1 1 30%;
                            margin: 1rem;

                            .upper-container {
                                width: 100%;
                            }
                        }
                    }
                }
            }
        }
        .twoCol-container {
            align-items: center;
            padding: 1rem 4rem;
            .container-column {
                justify-content: center;
            }
            .right {
                flex-basis: 50%;
                img {
                    max-width: 130%;
                }
            }
            .left {
                flex-basis: 40%;
                padding: 1rem;

                .legit-image {
                    max-width: 80%;
                    top: 5%;
                }
            }
        }
    }
}

//Tablet
@media only screen and (min-device-width: $tablet) and (max-device-width: $desktop) and (orientation: landscape) {
    .members-container {
        .container-row {
            .container-col {
                .multiCol-container {
                    .container-row {
                        .column {
                            max-width: unset;
                            min-width: unset;
                        }
                    }
                }
            }
        }
    }
}
