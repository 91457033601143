@import "../App.scss";

.access-container {
    flex: 1 1 100%;

    .twoCol-container {
        background-color: $custom-gray;
        padding: 4rem 6rem;

        .container-column {
            justify-content: flex-start;
            h1 {
                margin: 1rem 0;
                font-size: 36px;
                font-weight: 500;
            }
            p {
                margin: 1rem 0;
            }
        }
        .left {
            flex-basis: 40%;
            padding: 1rem 8rem;
            flex-direction: column;
            align-items: flex-start;

            .legit-image {
                position: absolute;
                max-width: 40%;
                right: 35%;
                top: 0;
            }
            p {
                margin: 1rem 0;
            }
            ul {
                list-style: none;
                margin-left: 10px;

                li {
                    margin: 0.5rem 0;
                }
            }
            ul li::before {
                content: "\2022";
                color: $custom-purple;
                font-weight: 900;
                display: inline-block;
                width: 1.5em;
                margin-left: -1em;
            }
            a {
                z-index: 10;
                button {
                    margin: 1rem 0;
                    width: unset;
                    padding: 0.7rem 1rem;
                }
            }
        }
        .right {
            flex-basis: 30%;

            img {
                transform: translate(-10%, 10%);
            }
        }
    }
    .twoCol-container-alt {
        padding: 4rem 6rem;
        align-items: flex-start;

        .container-column-alt {
            justify-content: flex-start;
            h1 {
                margin: 1rem 0;
                font-size: 36px;
                font-weight: 500;
            }
            p {
                margin: 1rem 0;
            }
        }
        .right {
            flex-basis: 40%;
            padding: 1rem 0 0 8rem;
            flex-direction: column; 
            align-items: flex-start;
            p {
                margin: 1rem 0;
            }
            ul {
                list-style: none;
                margin-left: 10px;

                li {
                    margin: 0.5rem 0;
                }
            }
            ul li::before {
                content: "\2022";
                color: $custom-purple;
                font-weight: 900;
                display: inline-block;
                width: 1.5em;
                margin-left: -1em;
            }
            ol {
                margin-left: 10px;

                li {
                    margin: 0.5rem 0;
                }
                ol li::before {
                    color: $custom-purple;
                }
            }
        }
        .left {
            flex: 1 1 30%;
            justify-content: center;
            padding-top: 3rem;

            .shape-image {
                position: absolute;
                z-index: -1;
                bottom: -15%;
            }
            .shape2-image {
                position: absolute;
                z-index: -1;
                bottom: -25%;
            }
        }
    }
}

// Phone
@media only screen and (min-device-width: $phone) and (max-device-width: $tablet) {
    .access-container {
        .text-container {
            h1 {
                font-size: 40px;
            }
        }
        .twoCol-container {
            padding: unset;
            text-align: center;

            .left {
                flex-basis: 100%;
                padding: 1rem 2rem;
                align-items: center;

                .legit-image {
                    right: unset;
                    top: 20%;
                    max-width: 80%;
                }
                a {
                    margin: 1rem 0;
                }
            }
            .right {
                img {
                    max-width: 100%;
                    transform: translate(0);
                }
            }
        }
        .twoCol-container-alt {
            text-align: center;
            padding: unset;
            align-items: center;
            flex-direction: column-reverse;

            .right {
            align-items: center;
                flex-basis: 100%;
                padding: 1rem 2rem;
            }
            .left {
                flex: 1 1 100%;
                justify-content: center;
                padding: 4rem 1rem;

                .shape-image {
                    max-width: 100%;
                    bottom: 5%;
                    left: -5%;
                }
                .shape2-image {
                    max-width: 75%;
                    bottom: 5%;
                    left: 5%;
                }
                .man-image {
                    max-width: 90%;
                }
            }
        }
    }
}

//Tablet
@media only screen and (min-device-width: $tablet) and (max-device-width: $desktop) {
    .access-container {
        .twoCol-container {
            align-items: flex-start;
            .container-column {
                justify-content: center;
            }
            .right {
                flex-basis: 100%;
                img {
                    max-width: 60%;
                    transform: translate(0);
                }
            }
            .left {
                flex-basis: 100%;
                padding: 1rem;

                .legit-image {
                    max-width: 80%;
                    top: 5%;
                }
            }
        }
        .twoCol-container-alt {
            .right {
                flex-basis: 100%;
                padding: 1rem 2rem;
            }
            .left {
                width: 100%;
                flex: 1 1 100%;
                justify-content: center;
                padding: 4rem 0 2rem 0;

                .shape-image {
                    max-width: 90%;
                    bottom: -5%;
                    left: 5%;
                }
                .shape2-image {
                    max-width: 75%;
                    bottom: -10%;
                    left: 5%;
                }
                .man-image {
                    max-width: 80%;
                }
            }
        }
    }
}

//Large Screen
@media only screen and (min-device-width: 2560px) {
    .access-container {
        .twoCol-container {
            padding: 6rem;
            .right {
                flex-basis: 40%;
            }
            .left {
                img {
                    left: 30%;
                    width: 35%;
                }
            }
        }
        .twoCol-container-alt {
            padding: 4rem 8rem 8rem 4rem;
            .left {
                flex: 1 1 40%;
                justify-content: center;

                .shape-image {
                    left: unset;
                }
            }
        }
    }
}
