@import "../../App.scss";

.footer-container {
    background-image: url("../../../assets/webAssets/bg-footer.jpg");
    background-size: cover;
    background-repeat: no-repeat;

    .footer-top {
        padding: 4rem 0 8rem 0;
        li {
            font-size: 18px;
            margin: 0.5rem 1.5rem;

            a:hover {
                color: $custom-purple;
            }
            a.active {
                padding-bottom: 5px;
                color: $custom-purple;
            }
        }
        hr {
            width: 90%;
        }
        .socials {
            a {
                margin: 2rem 4rem;
                img {
                    width: 35px;
                    height: 25px;
                }
            }
        }
    }
    .footer-bottom {
        background-color: transparent;
        padding: 4rem 0 1rem 0;

        .digigrow-container {
            a {
                display: flex;
                align-items: center;
                img {
                    margin-left: 1rem;
                }
            }
        }
    }
}
// Phone
@media only screen and (min-device-width: $phone) and (max-device-width: $tablet) {
    .footer-container {
        background-position-x: 50%;

        .footer-top {
            padding: 1rem 0 5rem 0;
            li {
                padding: 0.75rem 0;
                margin: unset;
            }
            .socials {
                display: none;
            }
        }
        .footer-bottom {
            background-color: transparent;
            padding: 1rem 0;

            .digigrow-container {
                a {
                    flex-direction: column;
                    img {
                        object-fit: contain;
                    }
                }
            }
        }
    }
}
//Tablet
@media only screen and (min-device-width: $tablet) and (max-device-width: $desktop) {
    .footer-container {
        .footer-top {
            .socials {
                margin-bottom: 2rem;
                display: flex;
            }
            li {
                margin: 0.5rem;
            }
        }
        .footer-bottom {
            padding: 1rem 0;
            justify-content: space-between;

            .digigrow-container {
                a {
                    flex-direction: row;
                }
            }
        }
    }
}

//Large Screens
@media only screen and (min-device-width: 1440px) {
    .footer-container {
        background-position-y: 30%;
    }
}
