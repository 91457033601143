@import "../../App.scss";

.video-container {
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.7);
    height: 100vh;
    width: 100vw;
    z-index: 20;
    overflow-y: hidden;

    #header-video {
        position: absolute;
        flex-grow: 1;
        bottom: 0;
        right: 0;
        z-index: -1;
        min-width: 100%;
        min-height: 100%;

        @media only screen and(max-width: $desktop) {
            width: auto;
            height: 100%;
        }
    }

    .video-close {
        color: $text-light;
        font-size: 2rem;
        position: absolute;
        top: 50px;
        right: 50px;
        text-shadow: 2px 2px 4px #000;
    }
}

// Phone
@media only screen and (min-device-width: $phone) and (max-device-width: $tablet) {
    .video-container {
        .video-close {
            top: 5px;
            right: 10px;
        }
    }
}

//Tablet
@media only screen and (min-device-width: $tablet) and (max-device-width: $desktop) {
    .video-container {
        .video-close {
            top: 15px;
            right: 15px;
        }
    }
}
