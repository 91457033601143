@import "../../includes/settings.scss";

.Accordian-Item {
    flex: 1 1 100%;
    background-color: white;
    flex-direction: column;
    margin: 1rem 0;
    align-items: flex-start;
    align-content: flex-start;
    background-clip: border-box;
    filter: drop-shadow(0 0 10px rgba(35, 31, 32, 0.13));

    .item-header {
        margin: 0;
        color: white;
        width: 100%;
        padding: unset;
        justify-content: flex-start;
        background-color: $text-dark;
        align-items: stretch;

        .title {
            flex: 1 1 auto;
            .card-header {
                h1 {
                    flex: 1 1 100%;
                    color: white;
                    margin: 2rem 0;
                    font-size: 36px;
                    font-weight: 500;
                    text-align: center;
                }
            }
        }

        .icon {
            flex: 0 1 auto;
            margin: 0 1rem;
            svg {
                height: 15px;
                width: 17px;
            }
        }
    }

    .body.hidden {
        margin-top: unset;
        padding: unset;
        max-height: 0;
        overflow: hidden;
    }

    .body.show {
        padding: unset;
        color: black;
        width: 100%;
        max-height: unset;
        flex-direction: row;
        width: 100%;
        align-items: flex-start;
        justify-content: flex-start;

        h3 {
            font-weight: 500;
            padding: 1rem;
            margin: 1rem;
        }

        .card-bottom {
            align-items: stretch;
            background-color: white;
            color: black;
            padding: 0 5%;
            justify-content: center;

            .step {
                margin: 1rem 0;

                .text {
                    justify-content: flex-start;
                    flex: 0 1 40%;

                    h1 {
                        width: 100%;
                        text-align: left;
                        margin: 1rem 0;
                        color: $text-dark;
                    }
                }

                .step-image {
                    flex: 0 1 50%;

                    img {
                        max-width: 100%;
                        height: auto;
                    }
                }
            }
        }
    }
}
