@import "../../App.scss";

.login-container {
    flex: 1 1 100%;
    display: flex;
    flex-direction: row;

    .form-container {
        margin: 5% 30%;
        flex: 1 1 80%;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;

        form {
            padding: 0 0 2rem 0;
            flex: 1 1 100%;

            .header {
                margin: 0 0 1rem 0;
                flex: 1 1 100%;
                background: linear-gradient(
                        to bottom,
                        rgba(0, 0, 0, 0) 0%,
                        rgba(0, 0, 0, 0) 59%,
                        rgba(0, 0, 0, 0.65) 100%
                    ),
                    url("../../../assets/webAssets/bg-footer.jpg") no-repeat;
                background-size: cover;

                h1 {
                    flex: 1 1 100%;
                    color: $text-dark;
                    margin: 2rem;
                    font-size: 26px;
                    font-weight: 500;
                    text-align: center;
                }
            }
            .field-group {
                margin: 0 10%;
            }
            .button-container {
                flex: 1 1 100%;
                justify-content: space-evenly;

                .btn-main {
                    flex: unset;
                    background-color: $custom-purple;
                    font-size: 1.2rem;
                    font-weight: 600;
                    border-color: $custom-purple;
                    border-radius: 5px;
                    color: #fff;
                    padding: 0.5rem 1rem;
                    transition: background-color 0.5s ease-in-out,
                        border-color 0.5s ease-in-out;
                }
            }
        }
    }

    .success-form {
        .body {
            p {
                padding: 2rem;
                text-align: center;
            }
        }
    }
}

// Phone
@media only screen and (min-device-width: $phone) and (max-device-width: $tablet) {
    .login-container {
        .form-container {
            margin: 5% 0 10% 0;

            form {
                .header {
                    background: unset;
                    background-color: $text-dark;
                
                    h1 {
                        color: whitesmoke;
                    }
                }
            }
        }
    }
}
//Tablet
@media only screen and (min-device-width: $tablet) and (max-device-width: $desktop) {
    .login-container {
        .form-container {
            margin: 5% 10%;
        }
    }
}
