@import "../App.scss";

.classes-container {
    flex: 1 1 100%;

    .twoCol-container {
        flex: 1 1 100%;
        padding: 4rem 2rem;
        background-image: linear-gradient(white, whitesmoke, $custom-gray);

        .container-column {
            flex: 1 1 40%;

            .features-container {
                justify-content: flex-start;
                flex-direction: column;
                padding-left: 30%;
                align-items: baseline;

                img {
                    position: absolute;
                    max-width: 60%;
                    top: -5%;
                }
                h1 {
                    z-index: 5;
                    margin: 1rem 0;
                    font-size: 46px;
                    font-weight: 500;
                }
                p {
                    margin: 1rem 0;
                    z-index: 5;
                }
                ul {
                    z-index: 5;
                    list-style: none;
                    margin-left: 10px;

                    li {
                        margin: 0.5rem 0;
                    }
                }
                ul li::before {
                    content: "\2022";
                    color: $custom-purple;
                    font-weight: 900;
                    display: inline-block;
                    width: 1.5em;
                    margin-left: -1em;
                }
                a {
                    z-index: 10;
                    button {
                        margin: 1rem 0;
                        width: unset;
                        padding: 0.7rem 1rem;
                    }
                }
            }
        }
    }
    .multiCol-container {
        padding: 0;
        flex: 1 1 100%;

        .error-container {
            width: 100%;
            flex: 1 1 100%;
            flex-direction: column;
            background-image: linear-gradient($custom-gray, whitesmoke, white);
            padding: 4rem 0;
            text-align: center;

            h3 {
                margin-bottom: 1rem;
            }
        }

        .container-row {
            background-color: $custom-gray;
            padding: 2rem 0;
            width: 100%;

            .container-column {
                flex-basis: 30%;
                align-content: flex-start;

                .card {
                    box-shadow: unset;
                    border-radius: 0;
                    padding-bottom: 2rem;
                    align-content: flex-start;

                    .image-container {
                        flex: 1 1 100%;
                        .image-link {
                            flex: 1 1 100%;
                            img {
                                width: 100%;
                            }
                        }
                    }
                    .body-container {
                        align-items: center;
                        text-align: center;
                        h2 {
                            margin: 0.5rem;
                            font-size: 32px;
                            font-weight: 500;
                        }
                        h3 {
                            margin: 1rem 0 0.5rem 0;
                            font-size: 14px;
                            font-weight: 600;
                            color: $card-text;
                        }
                        p {
                            margin: 1rem;
                            font-size: 14px;

                            overflow: hidden;
                            text-overflow: ellipsis;
                            display: -webkit-box;
                            -webkit-line-clamp: 3;
                            -webkit-box-orient: vertical;
                        }
                        button {
                            width: unset;
                            padding: 0.75rem 1rem;
                        }
                    }
                }
            }
        }
        .container-bottom {
            button {
                width: 200px;
                padding: 1rem;
            }
        }
    }
}

// Phone
@media only screen and (min-device-width: $phone) and (max-device-width: $tablet) {
    .classes-container {
        .twoCol-container {
            padding: unset;

            .container-column {
                flex-basis: 100%;
                margin: 2rem 0;

                .features-container {
                    padding-left: 0;
                    align-items: center;

                    img {
                        max-width: 80%;
                        top: 5%;
                    }
                }
            }
        }

        .multiCol-container {
            .container-row {
                padding: 0;
                .container-column {
                    flex-basis: 100%;

                    .card {
                        height: unset;
                        margin: 1rem 0;
                    }
                }
            }
        }
    }
}

//Tablet
@media only screen and (min-device-width: $tablet) and (max-device-width: $desktop) {
    .classes-container {
        .twoCol-container {
            align-items: center;
            padding: 1rem 4rem;
            .container-column {
                justify-content: center;
            }
            .right {
                flex-basis: 50%;
                img {
                    max-width: 130%;
                }
            }
            .left {
                flex-basis: 40%;
                padding: 1rem;

                .legit-image {
                    max-width: 80%;
                    top: 5%;
                }
            }
        }
        .multiCol-container {
            .container-row {
                .container-column {
                    flex-basis: 40%;
                    margin: 0 1rem;

                    .card {
                        margin: 1rem 0;
                        align-content: baseline;
                        height: 485px;
                    }
                }
            }
        }
    }
}
