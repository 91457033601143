@import './includes/settings.scss';

:root {
    font-size: 16px;
    font-family: 'Montserrat';
    --text-primary: #b6b6b6;
    --text-secondary: #ececec;
    --bg-primary: #23232e;
    --bg-secondary: #141418;
    --transition-speed: 600ms;
}
  
body {
    color: $text-dark;
    background-color: white;
    margin: 0;
    padding: 0;

    div {
        display: flex;
        position: relative;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        align-content: center;
    }

    li, ul, ol, p, h1, h2, h3, h4, h5, h6 {
        padding: unset;
        margin: unset;
    }

    a {
        color: inherit;
        text-decoration: none;
    }

    button {
        background: none;
        color: inherit;
        border: none;
        padding: 0;
        font: inherit;
        cursor: pointer;
        outline: inherit;
    }

    main {
        margin-left: 5rem;
        padding: 1rem;
        flex: 1 1 100%;
    }
}

body::-webkit-scrollbar {
    width: 0.25rem;
}
  
body::-webkit-scrollbar-track {
    background: #1e1e24;
}
  
body::-webkit-scrollbar-thumb {
    background: $primary;
}

// Theme Options
.dark {
    --text-primary: #b6b6b6;
    --text-secondary: #ececec;
    --bg-primary: #23232e;
    --bg-secondary: #141418;
}

.light {
    --text-primary: #1f1f1f;
    --text-secondary: #000000;
    --bg-primary: #ffffff;
    --bg-secondary: #e4e4e4;
}

.solar {
    --text-primary: #576e75;
    --text-secondary: #35535c;
    --bg-primary: #fdf6e3;
    --bg-secondary: #f5e5b8;
}

.theme-icon {
    display: none;
}

.dark #darkIcon {
    display: block;
}

.light #lightIcon {
    display: block;
}

.solar #solarIcon {
    display: block;
}

.text-muted {
    color: #6c757d!important;
}

// Button Styles
.btn-primary {
    background-color: $primary;
    font-family: 'Montserrat';
    font-size: 1.2rem;
    font-weight: 600;
    border-color: $primary;
    border-radius: 5px;
    color: $text-light;
    padding: 0.5rem 1rem;
    transition: background-color 0.5s ease-in-out, border-color 0.5s ease-in-out;

    &:hover { 
        background-color: $secondary;
        border-color: $secondary;
    }

    &:active { 
        text-decoration: underline;
    }

    &:focus { 
        text-decoration: underline;
        box-shadow: 0 0 0 0.2rem rgba($secondary,.5);
    } 
}

.btn-login {
    background: $login-custom-purple;
    border: 1px solid white;
    border-radius: 20px;
    padding: 0.5rem 0.75rem;
    width: 100px;
    color: white;
    font-weight: 600;

    &:hover {
        color: $custom-gray;
        border-color: $custom-purple;
    }
    &:active { 
        text-decoration: underline;
    }

    &:focus { 
        text-decoration: underline;
        box-shadow: 0 0 0 0.2rem rgba($custom-purple,.5);
    } 
}

.btn-watch {
    background: $custom-purple;
    border: 1px solid white;
    border-radius: 30px;
    padding: 0.4rem 0.75rem;
    width: 285px;
    color: white;
    font-weight: 600;

    &:hover {
        color: $custom-gray;
        border-color: $custom-purple;
    }
    &:active { 
        text-decoration: underline;
    }

    &:focus { 
        text-decoration: underline;
        box-shadow: 0 0 0 0.2rem rgba($custom-purple,.5);
    } 
    a {
        display: flex;
        align-items: center;
  
        img {
            margin-right: 1rem;
        }
    }
}

.btn-twitter {
    background: transparent;
    border: 1px solid #96ccf1;
    border-radius: 30px;
    padding: 0.4rem 0.75rem;
    width: 285px;
    color: $custom-twitter;
    font-weight: 600;

    &:hover {
        color: $custom-purple;
        border-color: $custom-purple;
    }
    &:active { 
        text-decoration: underline;
    }

    &:focus { 
        text-decoration: underline;
        box-shadow: 0 0 0 0.2rem rgba($custom-purple,.5);
    } 
    a {
        display: flex;
        align-items: center;
  
        img {
            margin-right: 1rem;
        }
    }
}

.btn-link {
    background-color: transparent;
    font-family: 'Montserrat';
    border: unset;
    color: $text-dark;
    font-weight: 700;
    text-decoration: underline;
}
