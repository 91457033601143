@import "../../App.scss";

.header-alt-container {
    flex: 1 1 100%;
    flex-direction: column;
    align-items: flex-start;

    background-image: url("../../../assets/webAssets/bg-page-title.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position-x: 10%;

    .navbar-container {
        background-color: white;
        .navbar {
            .navbar-mobile {
                display: none;
            }

            .navbar-desktop {
                margin-bottom: -100px;
                .navbar-left {
                    img {
                        width: 120%;
                    }
                }
                .navbar-right {
                    flex: 1 1 10%;
                    margin-top: 1.5rem;
                    justify-content: flex-end;

                    .btn-login {
                        background-color: $login-custom-purple;
                    }
                    .login-container {
                        .dropdown .dropbtn {
                            h3 {
                                color: $text-dark;
                            }
                        }
                    }
                    .dropdown {
                        flex-direction: column;
                        cursor: pointer;
                        flex: 1 1 100%;
                        align-content: flex-end;

                        .dropbtn {
                            padding: 0 2rem;
                            h3 {
                                font-size: 20px;
                                color: white;
                                font-style: italic;
                                font-weight: 500;
                            }
                            img {
                                transition: 0.6s;
                                margin-left: 1rem;
                            }
                            .upArrow {
                                transform: rotateX(180deg);
                            }
                        }
                        .dropdown-menu {
                            z-index: 100;
                            width: 100%;
                            align-items: flex-start;
                            display: none;
                            border-radius: 5px;
                            border: 1px solid $custom-gray;
                            box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
                            position: absolute;
                            top: 40px;
                            flex-direction: column;
                            padding: 1rem 0;
                            background-color: $custom-gray;
                            overflow: hidden;

                            .list-item {
                                width: 100%;
                                text-align: left;
                                justify-content: flex-start;
                                padding-left: 2rem;
                                cursor: pointer;
                                margin: 1rem 0;
                                font-size: 18px;
                                font-weight: 500;
                            }
                            .list-item:hover {
                                color: $custom-purple;
                                text-decoration: underline;
                            }
                        }
                        .show {
                            display: flex;
                        }
                    }
                }
            }
        }
    }
    .text-container {
        margin-left: 20%;
        padding: 2rem 0;
        text-align: center;

        h1 {
            margin: 0 0 2.5rem 0;
            color: $header-dark-text;
            font-size: 46px;
            font-weight: 500;
        }
    }
}

// Phone
@media only screen and (min-device-width: $phone) and (max-device-width: $tablet) {
    .header-alt-container {
        background-position-x: 20%;
        .navbar-container .navbar .navbar-mobile {
            display: flex;
        }
        .text-container {
            margin-left: unset;
            width: 100%;

            h1 {
                margin: 1rem 0;
            }
        }
    }
}
//Tablet
@media only screen and (min-device-width: $tablet) and (max-device-width: $desktop) {
    .header-alt-container {
    }
}

//
@media only screen and (width: $desktop) {
    .header-alt-container {
        .navbar-container {
            .navbar {
                padding-bottom: 2rem;
                .navbar-left .navbar-logo {
                    img {
                        width: 100%;
                    }
                }
            }
        }
        .text-container {
            h1 {
                margin: 0 0 2.5rem 2rem;
            }
        }
    }
}
